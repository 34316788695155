/* eslint-disable react/jsx-pascal-case */

import React from 'react'
import Layout from 'hoc/Layout/layout'
import Nav from 'components/Nav/nav'
import SubPageHeader from 'components/SubPageHeader'
import PrivacyPolicyPage from 'components/PrivacyPolicyPage'
import SEO from 'components/SEO'

const PrivacyPolicy = props => {
  return (
    <Layout>
      <SEO />
      <Nav {...props} black />
      <SubPageHeader title="Polityka prywatności" imageName="PRIVACY" />
      <PrivacyPolicyPage />
    </Layout>
  )
}

export default PrivacyPolicy
