import React from 'react'
import { Wrapper } from './styles'

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <h4>Polityka Prywatności</h4>
      <p>
        Zgodnie z art. 13 ust. 1 i ust. 2 rozporządzenia Parlamentu
        Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie
        ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
        sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
        95/46/WE (RODO), informujemy naszych klientów, że:
      </p>
      <p>
        Administratorem Pani/Pana danych osobowych jest Firma TRANSWELL
        TRANSPORT DEDYKOWANY SP. Z O.O. z siedzibą w Sosnowcu 41-200, ul.
        Szeroki Łan 14, numer telefonu: +48 664 956 691
      </p>
      <h4>
        Przetwarzanie Pani/Pana danych osobowych może się odbywać w celach:
      </h4>
      <p>
        realizacji usług w tym wykonywania przewozu ładunków, realizacji
        płatności oraz do celów księgowości,
        <br />
        komunikacji dotyczącej produktów i usług,
        <br />
        realizacji należności oraz dochodzenia lub obrony roszczeń związanych z
        współpracą,
      </p>
      <h4>Możemy przetwarzać następujące kategorie danych osobowych:</h4>
      <p>
        imię i nazwisko, nazwę firmy lub działalności,
        <br />
        dane identyfikacyjne firmy: NIP, REGON, numer rejestracji działalności,
        <br />
        adres i dane kontaktowe: ulica, nr domu, kod pocztowy, nazwę
        miejscowości, e-mail, telefon,
        <br />
        informacje dotyczące realizowanych transportów,
        <br />
        numery, daty, wartości i treści faktur oraz innych dokumentów
        finansowo-księgowych.
      </p>
      <h4>Możemy udostępnić Pani/Pana dane osobowe:</h4>
      <p>
        Podmiotom powiązanym i współpracującym, w przypadku, gdy udostępnienie
        jest niezbędne do prawidłowego działania podmiotu lub realizacji umowy
        oraz instytucjom upoważnionym do przetwarzania danych osobowych na mocy
        obowiązującego prawa.
      </p>
      <p>
        Pani/Pana dane osobowe będą przetwarzane przez okres realizacji umowy
        lub do czasu wygaśnięcia wzajemnych roszczeń wynikających z umowy. Po
        tym okresie dane osobowe będą przetwarzane jedynie w zakresie i przez
        czas wymagany przepisami prawa.
      </p>
      <h4>Pani/Pana dane nie będą profilowane.</h4>
      <p>
        Posiada Pani/Pan prawo dostępu do treści swoich danych osobowych, prawo
        do ich sprostowania, usunięcia, jak również prawo do ograniczenia ich
        przetwarzania/ prawo do cofnięcia zgody, prawo do przenoszenia danych,
        prawo do wniesienia sprzeciwu wobec przetwarzania Pani/Pana danych
        osobowych. Wycofanie zgody nie ma wpływu na legalność przetwarzania w
        oparciu o zgodę przed jej wycofaniem. Przysługuje Pani/Panu prawo
        wniesienia skargi do organu nadzorczego, jeśli Pani/Pana zdaniem,
        przetwarzanie Pani/Pana danych osobowych narusza przepisy unijnego
        rozporządzenia RODO.
      </p>
      <p>
        W przypadku pytań dotyczących przetwarzania danych osobowych prosimy o
        kontakt z Administratorem biuro@transwell.pl.
      </p>
    </Wrapper>
  )
}

export default PrivacyPolicy
