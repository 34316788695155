import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import * as Styled from './styles'

const SubPageHeader = ({ title, subtitle, imageName }) => {
  const { fleet, privacyPolicy, warehouse, same_day, shuttle } = useStaticQuery(
    graphql`
      query {
        fleet: file(name: { eq: "fleet-header" }) {
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 50
              placeholder: BLURRED
            )
          }
        }
        privacyPolicy: file(name: { eq: "privacy-policy" }) {
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 50
              placeholder: BLURRED
            )
          }
        }
        warehouse: file(name: { eq: "warehouse_background" }) {
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 50
              placeholder: BLURRED
            )
          }
        }
        same_day: file(name: { eq: "same_day_delivery" }) {
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 50
              placeholder: BLURRED
            )
          }
        }
        shuttle: file(name: { eq: "shuttle" }) {
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 50
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )
  let image

  switch (true) {
    case imageName === 'WAREHOUSE': {
      image = getImage(warehouse)
      break
    }
    case imageName === 'PRIVACY': {
      image = getImage(privacyPolicy)
      break
    }
    case imageName === 'FLEET': {
      image = getImage(fleet)
      break
    }
    case imageName === 'SAME_DAY': {
      image = getImage(same_day)
      break
    }
    case imageName === 'SHUTTLE': {
      image = getImage(shuttle)
      break
    }
    default: {
      image = null
    }
  }

  // const image = getImage(privacy ? privacyPolicy : placeholderImage)
  return (
    <Styled.Header subtitle={subtitle}>
      {imageName && (
        <GatsbyImage image={image} alt="Ręka podpisująca dokument" />
      )}

      <div className="content">
        <Styled.Title subtitle={subtitle}>{title}</Styled.Title>
        {subtitle && <Styled.SubTitle>{subtitle}</Styled.SubTitle>}
      </div>
    </Styled.Header>
  )
}

export default SubPageHeader
