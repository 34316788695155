import styled from 'styled-components'

export const Header = styled.header`
  position: relative;
  min-height: 60vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.black};
  @media only screen and (max-width: 1023px) {
    min-height: 42vh;
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 100%;
    width: 100%;
    ::before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
  }

  .content {
    position: absolute;
    display: flex;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    z-index: 1;
    padding: 110px 70px 70px;
    @media only screen and (max-width: 1023px) {
      padding: ${({ subtitle }) => (subtitle ? '80px 30px' : '20px')};
    }
  }
`

export const Title = styled.h1`
  font-size: 45px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  margin-bottom: 30px;
  @media only screen and (max-width: 1023px) {
    font-size: 36px;
    position: relative;
    margin-top: 65px;
  }
`
export const SubTitle = styled.h2`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  margin-bottom: 30px;
  @media only screen and (max-width: 1023px) {
    font-size: 16px;
  }
`
